import styled, { css, RuleSet } from 'styled-components';
import { palette } from '../../../styles/_palette';

export const ChipsLabel = styled.label<{
  $isActive: boolean;
  $isDark?: boolean;
  $borderRadius?: string;
  $customStyles?: RuleSet<object>;
}>`
  display: flex;
  padding: 15px;
  height: 44px;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: transparent;

  border-radius: ${({ $borderRadius }) => $borderRadius || '100px'};
  background: ${({ theme }) => theme.colors.background.tertiary};
  color: ${({ theme: { colors } }) => colors.text.primary};

  ${({ $isActive, $isDark, theme }) =>
    $isActive &&
    css`
      border-color: ${$isDark
        ? palette.cocoaBrown
        : theme.colors.border.primary};
      background-color: ${$isDark
        ? palette.cocoaBrown
        : theme.colors.systemColors.blue};
      color: ${$isDark ? palette.white : theme.colors.text.white};
    `}

  @media (hover: hover) {
    &:hover {
      ${({ $isActive }) =>
        !$isActive &&
        css`
          span {
            color: ${({ theme }) => theme.colors.systemColors.blue};
          }
        `}
    }
  }

  ${({ $customStyles }) =>
    $customStyles &&
    css`
      ${$customStyles}
    `}
`;

export const ChipsContent = styled.div<{ $hasIcon?: boolean }>`
  display: flex;
  align-items: center;
  outline: none;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.chips__box {
    display: flex;
    align-items: center;
  }
`;

export const ChipsText = styled.span<{ $isTextPadding?: boolean }>`
  font-size: 15px;
  font-weight: 400;
  ${({ $isTextPadding }) =>
    $isTextPadding &&
    css`
      padding-top: 3px;
    `}
`;

export const ResetButton = styled.div`
  margin-top: 1px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
