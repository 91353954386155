import Link from 'next/link';
import styled from 'styled-components';
import { FlexibleDiv } from '../../../styled/common';

export const RentalTabsContainer = styled(FlexibleDiv)`
  background-color: ${({ theme }) => theme.colors.background.primary};
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
  flex: auto;
  overflow-x: scroll;
  -webkit-overflow-scrolling: auto !important;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    label {
      padding: 8px 10px;
      color: ${({ theme }) => theme.colors.text.primary};
      margin-right: 0;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    gap: 0;
  }
`;

export const InitialRentalTabsContainer = styled(RentalTabsContainer)`
  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    opacity: 0;
  }
`;

export const TabLink = styled(Link)`
  text-decoration: none;
`;
