import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import cn from 'classnames';
import Icon from '../../Icon';
import { AngleDownIcon, CloseIconChip } from '../../../icons';
import { palette } from '../../../styles/_palette';

import { ChipsProps } from './types';
import { ChipsLabel, ChipsContent, ChipsText, ResetButton } from './styled';

export const Chips: React.FC<ChipsProps> = ({
  id,
  icon,
  text,
  type = 'single',
  name,
  stat,
  value,
  style,
  dirty,
  onClick,
  iconSize = 20,
  storedValue,
  borderRadius = '100px',
  resetHandler,
  cypress,
  isDark,
  iconType = 'close',
  useDirtyAsActive,
  color,
  iconActiveColor,
  iconNeedColorChange,
  activeIcon,
  hasCustomOnChange = false,
  isTextPadding = true,
  styles: customStyles
}) => {
  const normalizedStoredValue =
    storedValue === 'undefined' ? undefined : storedValue;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (useDirtyAsActive && dirty) {
      setIsActive(true);
      return;
    }

    if (
      ['checkbox', 'button'].includes(type) &&
      Array.isArray(normalizedStoredValue)
    ) {
      setIsActive(normalizedStoredValue.includes(value));
      return;
    }

    setIsActive(value !== undefined && value === normalizedStoredValue);
  }, [dirty, normalizedStoredValue, type, value, useDirtyAsActive]);

  const iconColor =
    iconActiveColor && isActive ? iconActiveColor : color || '#fff';

  const handleReset = (e: React.MouseEvent) => {
    e.stopPropagation();
    resetHandler?.(e);
  };

  const handleClick = (e: React.MouseEvent) => {
    onClick?.(e);
  };

  return (
    <ChipsLabel
      id={id}
      data-cy={cypress}
      data-active={`${isActive}`}
      data-stat={stat}
      $isActive={isActive}
      $isDark={isDark}
      $borderRadius={borderRadius}
      $customStyles={customStyles}
      style={style}
      onClick={handleClick}
    >
      {!hasCustomOnChange && type !== 'button' && (
        <Field
          name={name}
          component="input"
          type={type === 'single' ? 'radio' : type}
          className="display-none"
          data-cy={`chips-input-${value}`}
          value={value === undefined ? '' : value}
        />
      )}

      <ChipsContent
        $hasIcon={!!icon}
        className={cn('chips__content', { chips__box: icon })}
        tabIndex={0}
        role="button"
        onKeyPress={handleClick}
        data-cy="chips-content"
      >
        {icon && (
          <Icon
            IconComponent={isActive && activeIcon ? activeIcon : icon}
            width={iconSize}
            height={iconSize}
            color={iconColor}
            iconNeedColorChange={iconNeedColorChange}
          />
        )}
        <ChipsText $isTextPadding={isTextPadding} data-cy="chips-text">
          {text}
        </ChipsText>
      </ChipsContent>

      {iconType === 'angle' && (
        <ResetButton
          tabIndex={0}
          role="button"
          onClick={handleClick}
          onKeyPress={handleClick}
          data-cy="chips-reset-angle"
        >
          <AngleDownIcon fill={dirty ? palette.white : palette.cocoaBrown} />
        </ResetButton>
      )}

      {resetHandler && dirty && iconType === 'close' && (
        <ResetButton
          tabIndex={0}
          role="button"
          onClick={handleReset}
          onKeyPress={handleReset}
          data-cy="chips-reset"
        >
          <CloseIconChip fill={isDark ? palette.white : palette.cocoaBrown} />
        </ResetButton>
      )}
    </ChipsLabel>
  );
};
